import {useGLTF} from '@react-three/drei';
import {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import {Vector3} from 'three';
import {
  GirlJetpackCharacterBase,
  GirlJetpackCharacterBaseAnimations,
} from '../../../types/models/v2/bases/GirlJetpackCharacterBase';
import {DefaultFadeAnimation} from '../../models/animations/AnimationFadePrefabs';
import AnimationLoader, {
  AnimationLoaderRefProps,
} from '../../models/animations/AnimationLoader';
import {RepeatOnceAnimation} from '../../models/animations/AnimationRepeatPrefabs';
import GirlJetpackCharacter from '../../models/bases/GirlJetpackCharacter';
import {OnboardingJetpackProps, OnboardingJetpackRef} from './Contract';

const SuitGirlJetpack = forwardRef<
  OnboardingJetpackRef,
  OnboardingJetpackProps
>(({onLoaded}, ref) => {
  const {animations} = useGLTF(
    '/models/v2/fullModels/CoffeJetPackGirl.glb',
  ) as unknown as GirlJetpackCharacterBase;

  const [characterGroup, setCharacterGroup] = useState<THREE.Group>();
  const animatorRef =
    useRef<AnimationLoaderRefProps<GirlJetpackCharacterBaseAnimations>>(null);

  useImperativeHandle(ref, () => ({
    grow() {},
    shrink() {},
    // Bug in animations - right and left are mixed
    pointUpRight(playOnce) {
      animatorRef.current?.animate('PointLeftUp', {
        repeatMode: playOnce ? RepeatOnceAnimation : undefined,
        onFinished: playOnce
          ? {
              launchBaseAnimation: {
                fadeMode: DefaultFadeAnimation,
              },
            }
          : undefined,
      });
    },
    pointRight(playOnce) {
      animatorRef.current?.animate('PointLeft', {
        repeatMode: playOnce ? RepeatOnceAnimation : undefined,
        onFinished: playOnce
          ? {
              launchBaseAnimation: {
                fadeMode: DefaultFadeAnimation,
              },
            }
          : undefined,
      });
    },
    pointDownRight(playOnce) {
      animatorRef.current?.animate('PointLeftDown', {
        repeatMode: playOnce ? RepeatOnceAnimation : undefined,
        onFinished: playOnce
          ? {
              launchBaseAnimation: {
                fadeMode: DefaultFadeAnimation,
              },
            }
          : undefined,
      });
    },

    pointUpLeft(playOnce) {
      animatorRef.current?.animate('PointRightUp', {
        repeatMode: playOnce ? RepeatOnceAnimation : undefined,
        onFinished: playOnce
          ? {
              launchBaseAnimation: {
                fadeMode: DefaultFadeAnimation,
              },
            }
          : undefined,
      });
    },
    pointLeft(playOnce) {
      animatorRef.current?.animate('PointRight', {
        repeatMode: playOnce ? RepeatOnceAnimation : undefined,
        onFinished: playOnce
          ? {
              launchBaseAnimation: {
                fadeMode: DefaultFadeAnimation,
              },
            }
          : undefined,
      });
    },
    pointDownLeft(playOnce) {
      animatorRef.current?.animate('PointRightDown', {
        repeatMode: playOnce ? RepeatOnceAnimation : undefined,
        onFinished: playOnce
          ? {
              launchBaseAnimation: {
                fadeMode: DefaultFadeAnimation,
              },
            }
          : undefined,
      });
    },

    dance() {
      animatorRef.current?.animateRandom(['Dance2']);
    },
    land() {
      // animatorRef.current?.animate('Idle');
    },
    takeoff() {
      animatorRef.current?.animate('Fly');
    },
    wave() {
      animatorRef.current?.animate('Wave');
    },

    idle() {
      animatorRef.current?.animate('Idle');
    },
  }));

  return (
    <>
      <pointLight color="#fff" position={[0, 5, 5]} intensity={2} />
      <GirlJetpackCharacter
        onLoaded={setCharacterGroup}
        scale={new Vector3(1.1, 1.1, 1.1)}
        position={new Vector3(0, 0, 0)}
      />
      {characterGroup && (
        <AnimationLoader
          group={characterGroup}
          animations={animations}
          myRef={animatorRef}
          baseAnimation="Wave"
          onLoaded={onLoaded}
        />
      )}
    </>
  );
});

export default SuitGirlJetpack;
