import {IconBaseProps} from 'react-icons';
import {IoIosRadioButtonOff, IoIosRadioButtonOn} from 'react-icons/io';

interface TournamentCheckboxProps extends IconBaseProps {
  color: string;
  isChecked: boolean;
}

const TournamentCheckbox: React.FC<TournamentCheckboxProps> = ({
  isChecked,
  color,
  ...rest
}) => {
  if (isChecked) return <IoIosRadioButtonOn style={{color}} {...rest} />;
  return <IoIosRadioButtonOff style={{color}} {...rest} />;
};

export default TournamentCheckbox;
