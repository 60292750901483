import {useState} from 'react';

interface GiantDoubleSwitchProps {
  leftTitle: string;
  rigthTitle: string;

  leftChild: React.ReactNode;
  rightChild: React.ReactNode;

  startOnLeft?: boolean;

  mainColor: string;
}

const GiantDoubleSwitch: React.FC<GiantDoubleSwitchProps> = ({
  leftTitle,
  rigthTitle,
  leftChild,
  rightChild,
  mainColor,
  startOnLeft = true,
}) => {
  const [isLeft, setIsLeft] = useState(startOnLeft);
  return (
    <>
      <div className="bg-white rounded-3xl shadow-whole w-full flex items-center justify-center text-center text-3xl font-bold relative ">
        <div
          className="bg-[#E9E9E9] w-1/2 absolute h-full z-20  transition-all duration-200 rounded-3xl"
          style={{left: isLeft ? '0%' : '50%'}}
        ></div>
        <div
          className="flex-1 z-30 h-full my-4 cursor-pointer"
          onClick={() => setIsLeft(true)}
          style={{color: mainColor}}
        >
          {leftTitle}
        </div>
        <div
          className="flex-1 z-30 h-full my-4 cursor-pointer"
          onClick={() => setIsLeft(false)}
          style={{color: mainColor}}
        >
          {rigthTitle}
        </div>
      </div>
      <div className="mt-8 relative w-full">
        <div
          className="absolute w-full transition-all duration-200"
          style={{left: isLeft ? '0' : '-120%'}}
        >
          {leftChild}
        </div>
        <div
          className="absolute w-full transition-all duration-200"
          style={{left: isLeft ? '120%' : '0'}}
        >
          {rightChild}
        </div>
      </div>
    </>
  );
};

export default GiantDoubleSwitch;
