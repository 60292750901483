import classNames from 'classnames';
import SimpleButton from '../../../form/button/SimpleButton';
import {TournamentTask} from '../Types';
import TournamentCheckbox from './Checkbox';

interface TournamentTaskProps {
  task: TournamentTask;
  isLast: boolean;

  mainColor: string;
  oppositeColor: string;
}

const TournamentTaskElement: React.FC<TournamentTaskProps> = ({
  task,
  isLast,

  mainColor,
  oppositeColor,
}) => {
  return (
    <div className="flex gap-2 ">
      <TournamentCheckbox
        className="w-6 h-6"
        color={mainColor}
        isChecked={task.isCompleted}
      />
      <div
        className={classNames(
          'flex grow justify-center items-center pb-2 font-beVietnamPro',
          !isLast && 'border-b',
        )}
      >
        <div className="grow ">
          <div className="text-lg">{task.title}</div>
          <div className="text-[#8E8E92]">{task.description}</div>
        </div>
        {task.isClaimed || !task.isCompleted ? (
          <div className={classNames(task.isCompleted && 'line-through')}>
            {task.points}
            {task.currency}
          </div>
        ) : (
          <SimpleButton
            bgColor={mainColor}
            textColor={oppositeColor}
            className="text-lg "
          >
            <span className="font-semibold">Claim </span>
            <span>
              {task.points}
              {task.currency}
            </span>
          </SimpleButton>
        )}
      </div>
    </div>
  );
};

export default TournamentTaskElement;
