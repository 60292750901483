import TournamentTaskElement from './TournamentTask';
import {TournamentTasksProps} from './../Types';

const TournamentTasks: React.FC<TournamentTasksProps> = ({
  tasks,
  mainColor,
  oppositeColor,
}) => {
  return (
    <div className="p-3 space-y-2">
      {tasks.map((x, i) => (
        <TournamentTaskElement
          mainColor={mainColor}
          oppositeColor={oppositeColor}
          key={x.id}
          isLast={i == tasks.length - 1}
          task={x}
        />
      ))}
    </div>
  );
};

export default TournamentTasks;
