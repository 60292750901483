import classNames from 'classnames';
import React, {useState} from 'react';
import './Button.scoped.css';

type ButtonProps = {
  bgColor: string;
  textColor: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

// className применяем к трем разным блокам(button и двум div из которых состоит вся кнопка). Так получилось, что у нас кнопка состоит из трех частей: 1) подложка, 2) над ней наибольшая видимая часть всей кнопки и немного поднятая, чтобы от подложки осталась только внизу полоска, 3) от середины вверх идет светлая часть. И когда рендерят с кастомным стилем <Button className="123">, стиль 123 применяется сразу к трем этим частям кнопки. Или можно на выбор. Раньше он применялся только к одному блоку <button> (82 строка), потому что кнопка состояла из двух частей, этого button и светлой верхней части, которая была на 80% прозрачна и можно было применить стиль только к button. Но теперь если бы мы продолжали применять кастомные классы только к одной части кнопки, в итоге остальные не будут менять стиль и выглядит в итоге не очень.
const SimpleButton: React.FC<ButtonProps> = ({
  className,
  type,
  children,
  onClick,
  textColor,
  bgColor,
  disabled = false,
  ...rest
}) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <button
      type={type}
      disabled={disabled}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={classNames(
        'transition-colors text-center rounded-xl px-4 py-2',
        className,
      )}
      style={{
        color: textColor,
        background: !isHover ? bgColor : `${bgColor}80`,
      }}
      onClick={onClick}
      {...rest}
    >
      <span className="font-beVietnamPro pointer-events-none">{children}</span>
    </button>
  );
};

export default SimpleButton;
